import { Container } from "@mui/material";

const PrivacyPolicy = () => {
    return (
      <Container
        maxWidth="lg">
          <h1>Privacy Policy</h1>

<h2>1. What are we?</h2>
<p>
  Civitas Europe (hereinafter “Civitas”, “we” or “us”) is a network encompassing websites,
  mobile applications, clients, subsidiaries, applets and other applications that are
  developed.
</p>

<h2>2. What is a privacy policy?</h2>
<p>
  This privacy policy explains why and how we collect information, how the information is
  used and protected, and what rights you may exercise in relation to your data.
</p>
<p>
  This privacy policy applies to all personal information and to all our activities related
  to personal information.
</p>
<p>
  This privacy policy applies simultaneously with our cookie policy (which is available{" "}
  <a href="/cookies-policy" rel="noopener noreferrer">
    here
  </a>
  ).
</p>
<p>
  This privacy policy applies simultaneously with all other contractual relationships with
  you as a customer.
</p>

<h2>3. Does Civitas collect and process personal information of children?</h2>
<p>
  Persons under the age of 18 are not allowed to use our platform and our services. Civitas
  does not knowingly collect personal information from children under 18.
</p>

<h2>4. What about cookies and similar tools?</h2>
<p>
  We use cookies and similar tools for several purposes: to enhance your user experience, to
  provide and improve our services, etc. For further information, you can check our cookie
  policy{" "}
  <a href="/cookies-policy" rel="noopener noreferrer">
    here
  </a>
  .
</p>

<h2>5. Why do we process your data?</h2>
<p>Your personal information allows us to:</p>
<ul>
  <li>Utilize statistical tools to assess our client base and develop our products accordingly</li>
  <li>Make our website and our applications more intuitive to navigate and use</li>
  <li>Show advertisements that are relevant to your interests</li>
  <li>Protect you and our entire user base from fraud, abuse, and other malicious actions</li>
</ul>

<h2>6. Which data do we collect and process?</h2>
<p>In relation to the purposes outlined in Section 5 and on grounds of our legal obligations, our legitimate interests or your consent, we collect and process the following information:</p>
<ul>
  <li>Name</li>
  <li>Surname</li>
  <li>Email address</li>
  <li>Login information</li>
  <li>Attending university - optional</li>
  <li>Profile photo - optional</li>
</ul>
<p>
Please note that this is a list of all information we collect and process. However, your information is shared only with the entities and for the purposes specified in Section 9.
</p>

<h2>7. How long do we keep your information?</h2>
<p>Depending on the type of data and the purpose of our processing:</p>
<ul>
  <li>As long as you remain our user</li>
  <li>As long as the law requires us to do so</li>
</ul>

<h2>8. How do we protect your information?</h2>
<p>We utilize the following measures to make sure your data remains yours:</p>
<ul>
  <li>Data storage and transmission are protected by appropriate software and encryption methods</li>
  <li>Data storage and transmission are protected by strict procedural measures</li>
  <li>Data access is limited to a narrow number of employees and third parties with relevant needs or rights</li>
  <li>
    Data access is limited to persons and organizations who are bound by ours or follow similar
    data protection practices and procedures
  </li>
</ul>
<p>Please keep in mind that with minimal effort you can keep your account and your personal information safe from most malicious access attempts:</p>
<ul>
  <li>Utilize a unique password</li>
  <li>Log out of your sessions on public and shared devices</li>
</ul>

<h2>9. Who has access to your data and to whom is it disclosed?</h2>
<p>
  The following entities and persons may have access to your information:
</p>
<ul>
  <li>
    <strong>Legal authorities:</strong> We only share the personal information that we are
    obliged to and only with entities sanctioned by law to process it. Examples of this are the courts or the competent law enforcement authorities.
  </li>
</ul>

<h2>10. Is your data used for advertising?</h2>
<p>
  Some of your personal information is shared with our marketing partners. This improves your
  general user experience and is done in a manner that protects your physical identity. You can object to this at any time – please refer to Section 12 below.
</p>

<h2>11. What are your rights and how can you exercise them?</h2>
      <p>
        As a client of Civitas and in relation to your personal information, you have a number of
        rights. You can get a better understanding of them in the list below:
      </p>
      <ul>
        <li>
          <strong>Right to access:</strong> You have the right to obtain a copy of your personal
          information that we process and some information about the way we process it. A small fee
          may be applicable.
        </li>
        <li>
          <strong>Right to rectify:</strong> You can request the rectification of inaccurate or
          incomplete personal information.
        </li>
        <li>
          <strong>Right to delete:</strong> Under certain conditions, you can request your personal
          information to be deleted.
        </li>
        <li>
          <strong>Right to object:</strong> Under certain conditions, you can object to the
          processing of your personal information. An example of this would be the utilization of
          data for marketing purposes.
        </li>
        <li>
          <strong>Right to restrict processing:</strong> Under certain conditions, you have the
          right to temporarily restrict the processing of your personal information by us, provided
          there are valid grounds for doing so. This right is limited by law – we might still
          continue to process some of your information to fulfill our legal obligations.
        </li>
        <li>
          <strong>Right to portability:</strong> In some cases, you can ask to receive your personal
          information which you have provided to us in a structured, commonly used, and
          machine-readable format, or, when this is possible, that we communicate your personal
          information on your behalf directly to another data controller.
        </li>
        <li>
          <strong>Right to withdraw your consent:</strong> For processing requiring your consent,
          you have the right to withdraw your consent at any time. Please note that exercising this
          right does not affect the lawfulness of the processing based on the consent given before
          the withdrawal of the latter.
        </li>
        <li>
          <strong>Right to lodge a complaint with the relevant data protection authority:</strong> We are confident we can help with most issues related to our processing of your personal information. However, if you have unresolved concerns, you also have the right to complain to the RELEVANT DATA PROTECTION INSTITUTION or the data protection authority in the location in which you live, work or believe a data protection breach has occurred.
        </li>
      </ul>
      <p>
        If you wish to exercise those rights, you can contact our data protection officer at{" "}
        <a href="mailto:info@civitaseu.com">info@civitaseu.com</a>. For a faster processing of your
        request, clearly state its nature and provide as much details about the information it
        concerns.
      </p>

      <h2>12. Account Deletion Process</h2>
      <h3>1. Requesting Account Deletion</h3>
      <p>
        Users can request account deletion by contacting Civitas support at{" "}
        <a href="mailto:info@civitaseu.com">info@civitaseu.com</a>.
      </p>
      <h3>2. Verification</h3>
      <p>
        To prevent unauthorized deletions, users will need to verify their identity. This may
        involve confirming their email address or phone number associated with the account.
      </p>
      <h3>3. Confirmation</h3>
      <p>
        Once verification is complete, users will receive a confirmation prompt to proceed with the
        account deletion. Users must confirm their intention to delete the account.
      </p>
      <h3>4. Loss of Access</h3>
      <p>
        Upon deletion, users will no longer be able to log in or use any features of the website.
      </p>
      <h3>5. Data Deletion</h3>
      <p>
        All personal data, including but not limited to profile information, usage history, and
        preferences, will be permanently deleted from our servers, except for data we are required
        to retain under applicable laws and regulations. Data retained for legal purposes will be
        securely stored and will not be used for any other purposes.
      </p>
      <h3>6. Non-Recoverable</h3>
      <p>
        Once the account is deleted, the action cannot be undone. Users who wish to use the website
        again will need to create a new account.
      </p>

      <h3>Legal Retention</h3>
      <p>
        <ul>
          <li>
            Civitas will retain certain data as required by applicable laws and regulations. This
            may include data related to legal compliance records or other data mandated by law.
          </li>
          <li>
            Retained data will be handled in accordance with our Privacy Policy and applicable data
            protection regulations.
          </li>
        </ul>
      </p>

<h2>13. Contact Information</h2>
<p>
  If you have any issues related to the collection and processing of your personal data, you
  can contact our data protection officer at{" "}
  <a href="mailto:info@civitaseu.com">info@civitaseu.com</a>.
</p>

<h2>14. Revisions</h2>
<p>
  This privacy statement has been updated on 21.11.2024. Unless otherwise stated, the current
  version is applicable to all your personal information available to us.
</p>
<p>
  Our privacy statement applies to our website only. If you follow an external link from our
  website or applications, you should read their privacy policy.
</p>  
    </Container>);
};

export default PrivacyPolicy;