import { Container } from "@mui/material";

const TermsOfService = () => {
  return (
    <Container
      maxWidth="lg">

<h1>Terms & Conditions for Civitas Europe LLC</h1>
      <p>
        These current terms and conditions govern the terms of use of{" "}
        <a href="https://www.civitaseu.com" rel="noopener noreferrer">
          www.civitaseu.com
        </a>{" "}
        and the mobile application, hereinafter referred to as "the Platform".
      </p>
      <p>
        The Platform is operated by Civitas Europe LLC., a company registered under the laws of
        Bulgaria, registry code 208066883. If necessary, you can contact us through our contact
        form published on the Website at{" "}
        <a href="https://www.civitaseu.com" rel="noopener noreferrer">
          www.civitaseu.com
        </a>.
      </p>
      <p>
        By using the Platform, the user agrees to these general terms and conditions. If you do not
        agree with the same, you should refrain from using the Platform.
      </p>

      <h2>1. Intellectual Property</h2>
      <p>
        <strong>1.1.</strong> All intellectual property rights regarding the information resources
        and content of the website and the Platform are exercised by the Operator. They are
        protected by the applicable copyright legislation of the Republic of Bulgaria, and any use
        of content from the website or the Platform without the consent of the Operator is a
        violation of the law, which may result in civil, administrative criminal or criminal liability.
      </p>
      <p>
        <strong>1.2.</strong> In the case of copying or reproduction of information outside of the
        permitted, as well as in case of any other violation of intellectual property rights on the
        Operator's resources, the Operator has the right to claim compensation for the direct and
        indirect damages suffered in full.
      </p>

      <h2>2. Limitation of Liability</h2>
      <p>
        <strong>2.1.</strong> The operator takes the necessary measures to keep the content of the
        website and the Platform up-to-date, but is not responsible for the actual up-to-dateness of
        the same. In this regard, the Operator is not liable for damages, including direct and
        indirect damages, including lost profits, that a user of the Platform would incur under any
        circumstances related to the published content of the Platform and access to it. By using
        the Platform, users assume all risks associated therewith, including any risk to users' end
        devices, software or data.
      </p>
      <p>
        <strong>2.2.</strong> The operator is not responsible for malicious actions by third parties
        to which the user may become subject when using the website or the Platform. The User uses
        the website and the Platform at their own risk.
      </p>

      <h2>3. User Data</h2>
      <p>
        <strong>3.1.</strong> The Civitas Account registration application requires you to provide
        personal information.
      </p>
      <p>
        <strong>3.2.</strong> This information is subject to Civitas Privacy Policy, which you can
        find{" "}
        <a href="https://www.civitaseu.com/privacy-policy" rel="noopener noreferrer">
          here
        </a>.
      </p>
      <p>
        <strong>3.3.</strong> Please read it carefully. It contains important material regarding
        your personal information and imposes multiple obligations to you, some of which are to:
      </p>
      <ul>
        <li>Provide true and accurate information when required</li>
        <li>Update the information you have provided when changes occur</li>
      </ul>

      <h2>4. Reward System and Participation</h2>
      <h3>4.1. Eligibility</h3>
      <p>
        - The reward system is open only to users who are registered on the Platform. <br />
        - To be eligible for rewards, users must meet the following criteria:
      </p>
      <ul>
        <li>Reside in a country within Europe.</li>
        <li>Hold citizenship of a European country.</li>
      </ul>
      <p>
        Users who do not meet both residence and citizenship requirements will not be eligible to
        participate in the reward system, regardless of their registration status.
      </p>

      <h3>4.2. Earning Tickets</h3>
      <p>
        - Users earn virtual tickets (“tickets”) based on the time they spend engaging with the
        platform’s features. The rate of earning tickets is determined by the Operator and may
        change at any time. <br />
        - Tickets are non-transferable, hold no monetary value, and are exclusively used for entries
        into reward draws.
      </p>

      <h3>4.3. Reward Draws</h3>
      <p>
        - Tickets provide entries into random reward draws conducted when the Operator deems enough
        tickets have been distributed. <br />
        - The remaining tickets that need to be distributed until the Operator executes the reward
        draw will always be visible on the Platform. <br />
        - Winning is determined solely by chance through a random selection process. <br />
        - Rewards and their values are determined by the Operator and are subject to change. <br />
        - Winners will be notified via the email associated with their account and must claim their
        rewards within the specified timeframe.
      </p>

      <h3>4.4. Limitations</h3>
      <p>
        - Users found engaging in fraudulent, automated, or other improper methods to earn tickets
        may face account suspension and forfeiture of their tickets. <br />
        - Rewards are non-transferable, and substitutions will only be made at the Operator’s
        discretion.
      </p>

      <h3>4.5. Disclaimer</h3>
      <p>
        - The reward system is for recreational purposes and does not constitute a lottery or
        gambling activity. Participation is free, and no purchase is required. <br />
        - The Operator reserves the right to modify, suspend, or terminate the reward system at its
        discretion.
      </p>
      <h2>5. Miscellaneous</h2>
      <p>
        <strong>5.1.</strong> The Operator reserves the right to amend these general terms and
        conditions at its discretion, as at any moment the current terms and conditions for use of
        the Platform will be published on the same.
      </p>
      <p>
        <strong>5.2.</strong> The provisions of the current legislation of the Republic of Bulgaria
        apply to all matters not settled by these General Terms and Conditions.
      </p>

      <h2>6. Disclaimer of Warranties</h2>
      <p>
        <strong>6.1.</strong> No statement made by Civitas Europe LLC shall be interpreted as a
        warranty in regard to the Interface, the Services, or any other services or resources
        provided by or on behalf of Civitas.
      </p>
      <p>
        <strong>6.2.</strong> The Civitas website and Platform and any other service or resource are
        provided on an "as is" and "as available" basis.
      </p>
      <p>
        <strong>6.3.</strong> Civitas disclaims, to the fullest extent permitted by the applicable
        law, any representations and warranties of any kind, expressed or implied, including, but
        not limited to, warranties of merchantability and fitness for a particular purpose, or
        non-infringement. You agree that you use the website and the Platform at your own risk.
      </p>
      <p>
        <strong>6.4.</strong> Civitas does not represent or warrant that access to the website or
        the Platform will be continuous, uninterrupted, timely or secure.
      </p>
      <p>
        <strong>6.5.</strong> Civitas does not represent or warrant that the website or the Platform
        and all other services or resources provided by or on behalf of Civitas are accurate,
        completely reliable, current, or free of errors, viruses, defects or other harmful content.
      </p>
      <p>
        <strong>6.6.</strong> Civitas strives to provide you with complete and accurate information.
        This means that the content on the website and the Platform is frequently updated, sometimes
        without notice (where allowed by the applicable law). It is your responsibility to verify
        all available information before taking action based on it.
      </p>

      <h2>7. Governing Law</h2>
      <p>
        <strong>7.1.</strong> This Agreement, including its construction, validity and
        interpretation, shall be governed by the laws of the Republic of Bulgaria.
      </p>

      <h2>8. Modification</h2>
      <p>
        <strong>8.1.</strong> Civitas shall have the right, at their own discretion, to modify this
        Agreement.
      </p>
      <p>
        <strong>8.2.</strong> Civitas shall announce all modifications to this Agreement (see x.
        Announcements).
      </p>
      <p>
        <strong>8.3.</strong> Civitas shall further notify their users by maintaining a current
        version of the Agreement at{" "}
        <a href="https://www.civitaseu.com/terms-of-service" rel="noopener noreferrer">
          https://www.civitaseu.com/terms-of-service
        </a>{" "}
        and by updating the date at the top of the Agreement.
      </p>
      <p>
        <strong>8.4.</strong> Civitas shall provide you with a direct notification via email, where
        required by the applicable law, or deemed appropriate by Civitas.
      </p>
      <p>
        <strong>8.5.</strong> All modifications to this Agreement, where lawful, shall become
        effective immediately after they are posted.
      </p>
      <p>
        <strong>8.6.</strong> Continued use or accessing of the Platform by you will serve as
        confirmation of your acceptance of any modifications to this Agreement. If you do not agree
        with any modification to this Agreement, you must immediately stop accessing and using the
        Interface.
      </p>

      <h2>9. Language and Translations</h2>
      <p>
        <strong>9.1.</strong> Any translation of this Agreement done by Civitas shall be for your
        convenience only. The English version of this Agreement shall prevail in any case of
        ambiguity in a translated version or of discrepancy between the English version and a
        translated version.
      </p>

      <h2>10. Force Majeure</h2>
      <p>
        <strong>10.1.</strong> Civitas will not be liable for any delay or failure to perform as
        required by this Agreement because of any cause or condition beyond Civitas’s reasonable
        control.
      </p>

      <h2>11. Severability</h2>
      <p>
        <strong>11.1.</strong> If any portion of this Agreement, including the dispute resolution
        clause and the arbitration agreement contained therein, is held invalid or unenforceable,
        such invalidity or enforceability will not affect the other provisions of the Agreement,
        which will remain in full force and effect, and the invalid or unenforceable portion will be
        given effect to the greatest extent possible.
      </p>

    </Container>)
};

export default TermsOfService;