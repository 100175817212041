import { Routes, Route, HashRouter } from "react-router-dom"
import Overview from "pages/overview/overview"
import ReactGA from "react-ga4";
import TermsOfService from "pages/terms_of_service/tems_of_service";
import PrivacyPolicy from "pages/privacy_policy/privacy_policy";
import CookiesPolicy from "pages/cookies_policy/cookies_policy";

ReactGA.initialize("G-1EJXPM7BPD");

const RootRouter = () => {
    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Overview />} />
                <Route path="terms-of-service" element={<TermsOfService />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/cookies-policy" element={<CookiesPolicy />} />
            </Routes>
        </HashRouter>
    )
}

export default RootRouter