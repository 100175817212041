import { Box, Container, Link, Typography } from "@mui/material"
import styles from 'utilities/global_styles.module.css'

const BottomBar = () => {
    return (
        <Container>
                    <Box maxWidth="lg" justifyContent="center"style={{ zIndex: 999 }} sx={{marginBottom: "20px", alignItems: "center", display: "flex" }}>
            <Typography component="span" style={{ margin: 0, padding: 0, display: "flex", gap: "8px", fontSize: "0.875rem" }}>
      <span>
        <Link
          href="/terms-of-service"
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
          sx={{ color: "black" }}
        >
            Terms of Service
        </Link>
      </span>
      <Typography component="span" sx={{ margin: "0 8px", color: "black", fontSize: "0.875rem" }}>|</Typography>
      <span>
        <Link
          href="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
          underline="none"
          sx={{ color: "black" }}
        >
            Privacy Policy         
        </Link>
      </span>
    </Typography>
    </Box>
        <Box maxWidth="lg" justifyContent="center"style={{ zIndex: 999 }} sx={{marginBottom: "20px", alignItems: "center", display: "flex" }}>
            <Box className={styles.footerPrimary}>Copywright 2024</Box>
            <Box className={styles.footerSecondary} marginLeft="6px">civitaseu.com</Box>
        </Box>
        </Container>
    );
}

export default BottomBar